import JSPM from 'jsprintmanager'

const initJSPrintManager = async () => {
  JSPM.JSPrintManager.auto_reconnect = true
  JSPM.JSPrintManager.start()

  JSPM.JSPrintManager.WS.onStatusChanged = function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      if (jspmWSStatus()) {
        JSPM.JSPrintManager.getPrinters().then(function (myPrinters) {
          for (var i = 0; i < myPrinters.length; i++) {
            console.log(myPrinters[i])
          }
        })
      }
    }
  }
}

const getPrinter = async () => {
  if (jspmWSStatus()) {
    return await JSPM.JSPrintManager.getPrinters()
  }
}

const getDefaultPrinter = async () => {
  if (jspmWSStatus()) {
    return new JSPM.DefaultPrinter()
  }
}

const jspmWSStatus = async () => {
  if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
    console.log('JSPrintManager Ready!')
    return true
  } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
    alert('ท่านยังไม่ได้ติดตั้งซอฟแวร์พิมพ์ฉลาก กรุณาติดต่อผู้ดูแลระบบ หรือ vwellsupport@scg.com')
    // alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm')
    return false
  } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
    alert('JSPM has blocked this website!')
    return false
  }
}

const print = async (url, printer, form, patient) => {
  if (jspmWSStatus()) {
    // Create a ClientPrintJob
    var cpj = new JSPM.ClientPrintJob()
    if (!printer) {
      cpj.clientPrinter = new JSPM.DefaultPrinter()
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(printer)
    }
    const site = JSON.parse(sessionStorage.getItem('Site'))
    var CR = '\x0D'
    var cmds = '^Q75,3\n^W102\n^H12\n^P1\n^S3\n^AT\n^C1\n^R0\n~Q+0\n^O0\n^D0\n^E18\n~R255^C1\n'
    cmds += '^L' + CR
    cmds += 'Dy4-me-dd' + CR
    cmds += 'Th:m:s' + CR
    cmds += 'W150,60,5,2,M,8,3,' + url.length + ',0' + CR
    cmds += url + CR
    cmds += 'ATA,430,50,45,45,0,0BE,A,0,ใช้โทรศัพท์มือถือ' + CR
    cmds += 'ATA,430,100,45,45,0,0BE,A,0,สแกนคิวอาร์โค้ด' + CR
    cmds += 'ATA,430,150,45,45,0,0BE,A,0,เพื่อบันทึกข้อมูล' + CR
    cmds += 'ATA,150,350,35,35,0,0BE,A,0,หน่วยบริการ ' + site.siteName + CR
    cmds += 'ATA,150,400,35,35,0,0BE,A,0,แบบฟอร์ม ' + form.formTypeName + CR
    cmds += 'ATA,150,450,35,35,0,0BE,A,0,ผู้รับบริการ ' + patient.fullname + CR
    const instruction = getPrintCmd({ margin: 150, size: 35, text: patient.address, position: 500, length: 42 })
    cmds += instruction.command
    cmds += 'E' + CR
    cpj.printerCommands = cmds
    cpj.sendToClient()
    this.$emit('onSubmit')
  }
}

// eslint-disable-next-line no-unused-vars
const printContinuouse = async (urlList, printer, form) => {
  if (jspmWSStatus()) {
    // Create a ClientPrintJob
    var cpj = new JSPM.ClientPrintJob()
    if (!printer) {
      cpj.clientPrinter = new JSPM.DefaultPrinter()
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(printer)
    }
    const site = JSON.parse(sessionStorage.getItem('Site'))
    var CR = '\x0D'
    var cmds = ''

    urlList.forEach(patient => {
      cmds += '^Q75,3\n^W102\n^H12\n^P1\n^S3\n^AT\n^C1\n^R0\n~Q+0\n^O0\n^D0\n^E18\n~R255^C1\n'
      cmds += '^L' + CR
      cmds += 'Dy4-me-dd' + CR
      cmds += 'Th:m:s' + CR
      cmds += 'W150,60,5,2,M,8,3,' + patient.url.length + ',0' + CR
      cmds += patient.url + CR
      cmds += 'ATA,430,50,45,45,0,0BE,A,0,ใช้โทรศัพท์มือถือ' + CR
      cmds += 'ATA,430,100,45,45,0,0BE,A,0,สแกนคิวอาร์โค้ด' + CR
      cmds += 'ATA,430,150,45,45,0,0BE,A,0,เพื่อบันทึกข้อมูล' + CR
      cmds += 'ATA,150,350,35,35,0,0BE,A,0,หน่วยบริการ ' + site.siteName + CR
      cmds += 'ATA,150,400,35,35,0,0BE,A,0,แบบฟอร์ม ' + form.formTypeName + CR
      cmds += 'ATA,150,450,35,35,0,0BE,A,0,ผู้รับบริการ ' + patient.info.fullname + CR
      const instruction = getPrintCmd({ margin: 150, size: 35, text: (patient.info.address) ? patient.info.address : '', position: 500, length: 42 })
      // const instruction = getPrintCmd({ margin: 150, size: 35, text: 'คำแนะนำ วัดความดันโลหิตวัน 2 ครั้ง หลังตื่นนอน และ ก่อนเข้านอน', position: 500, length: 42 })
      cmds += instruction.command
      cmds += 'E' + CR
      console.log(patient.info.fullname)
    })
    cpj.printerCommands = cmds
    cpj.sendToClient()
  }
}

const checkIsChar = (str) => {
  return /^[ัิ-ู็-์ีั]$/.test(str)
}
const getPrintCmd = ({ margin, size, text, position, length }) => {
  const arrayStr = text.split('')
  let counter = 0
  let cmd = ''
  const formatedStr = []
  let str = ''
  for (var i = 0; i < arrayStr.length; i++) {
    if (!checkIsChar(arrayStr[i])) {
      counter++
    }
    if (counter < length) {
      str += arrayStr[i]
      if (i === (arrayStr.length - 1)) {
        formatedStr.push(str)
      }
    } else {
      formatedStr.push(str)
      str = ''
      str += arrayStr[i]
      counter = 0
    }
  }
  for (let i = 0; i < formatedStr.length; i++) {
    cmd += 'ATA,' + margin + ',' + (position) + ',' + size + ',' + size + ',0,0BE,A,0,' + formatedStr[i] + '\n'
    position += size
    console.log(cmd)
  }
  return { command: cmd, position: position }
}

export {
  initJSPrintManager,
  jspmWSStatus,
  print,
  getPrinter,
  getDefaultPrinter,
  printContinuouse
}
