<template>
    <v-card outlined @click="onClick"
            :style="{
                    border: active? '2px solid var(--v-primary-base) !important' : '2px solid #DBDCE7 !important'
                    }">
              <v-card-text class="text-center">
                  <v-icon>{{icon}}</v-icon>
                  <!-- <div>หมายเลขเครื่อง</div> -->
                  <div>{{title}}</div>
              </v-card-text>
          </v-card>
</template>

<script>
export default {
  model: {
    prop: 'active',
    event: 'change'
  },
  props: ['title', 'active', 'index', 'icon'],
  methods: {
    onClick () {
      this.$emit('update:active', true)
      this.$emit('togle', this.index)
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }

}
</script>

<style>

</style>
