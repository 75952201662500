<template>
  <v-card :loading="loading">
      <v-card-title class="text-h4 font-weight-bold">แก้ไขข้อมูล<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="text-left">
         <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense class="ma-0 pa-0">
              <v-col cols="12">
                  <TextField :title="'ชื่อ-นามสกุล'" :required="true" v-model="value.fullname" :rules="fullnameRules"/>
              </v-col>
               <v-col cols="12">
                 <DatePicker :title="'วันเกิด'" :placeholder="'โปรดเลือก'" v-model="value.birthdate"/>
              </v-col>
              <v-col cols="12">
                  <TextField :title="'เลขประจำตัวประชาชน'"  v-mask="'#-####-#####-##-#'" :required="true" v-model="value.cid" :rules="cidRules"/>
              </v-col>
                <v-col cols="12">
                    <label class="text-title-4 natural--text text--darken-4">
                        เพศ <span class="error--text">*</span></label>
                <v-radio-group
      v-model="value.gender"
      row
      dense
    >
    <v-spacer/>
      <v-radio
        label="ชาย"
        value="ชาย"
      ></v-radio>
       <v-spacer/>
      <v-radio
        label="หญิง"
        value="หญิง"
      ></v-radio>
       <v-spacer/>
    </v-radio-group>
              </v-col>
               <v-col cols="12">
                  <TextArea :title="'ที่อยู่'" v-model="value.address"/>
              </v-col>
               <v-col cols="12">
                  <TextField :title="'เบอร์ติดต่อ'"  v-mask="'###-###-####'"
    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"  :required="true" v-model="value.mobile" :rules="mobileRules"/>
              </v-col>
          </v-row>
             </v-form>
      </v-card-text>
      <v-card-actions>
     <v-row class="pr-3 pl-3">
              <v-col cols="6" class="pa-2"><v-btn block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn block color="primary" large @click="onSubmit()"><span class="text-title-2">{{action}}</span></v-btn></v-col>
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import TextField from '@/components/common/TextField'
import DatePicker from '@/components/common/DatePicker'
import TextArea from '@/components/common/TextArea'
import { mask } from 'vue-the-mask'
// import Select from '@/components/common/Select'

export default {
  directives: { mask },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean
    },
    yearItems: {
      type: Array
    },
    value: {
      type: Object
    }
  },
  components: {
    TextField,
    DatePicker,
    TextArea
    // Select
  },
  data () {
    return {
      fullname: null,
      address: null,
      cid: null,
      mobile: null,
      gender: null,
      loading: false,
      valid: false,
      birthdate: new Date().toISOString().substr(0, 10),
      fullnameRules: [
        v => !!v || 'กรุณาระบุชื่อ นามสกุล'
      ],
      cidRules: [v => !!v || 'กรุณาระบุเลขประจำตัวประชาชน'],
      addressRules: [v => !!v || 'กรุณาระบุที่อยู่'],
      mobileRules: [v => !!v || 'กรุณาระบุเบอร์ติดต่อ']
    }
  },
  watch: {
    status (val) {
      if (val) {
        this.type = null
        this.$refs.form.reset()
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        // this.$emit('change', { fullname: this.fullname, address: this.address, mobile: this.mobile.replace(/^(\+)|\D/g, '$1'), gender: this.gender, birthdate: this.birthdate, cid: this.cid.replace(/^(\+)|\D/g, '$1') })
        this.$emit('onSubmit')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
