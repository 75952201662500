<template>
    <v-row class="mb-2">
       <v-col v-for="(item, index) in items" :key="index" cols="6">
        <MethodCardWidget :title="item.title" :index="index" :active.sync="item.active" @togle="togle" :icon="item.icon"/>
      </v-col>
  </v-row>
</template>

<script>
import MethodCardWidget from '@/components/common/MethodCardWidget'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: ['value', 'error', 'choices'],
  components: {
    MethodCardWidget
  },
  data () {
    return {
      items: []
    }
  },
  mounted () {
    if (this.choices) {
      for (let i = 0; i < this.choices.length; i++) {
        if (this.value && this.value.value === this.choices[i].value) {
          this.items.push({ title: this.choices[i].text, value: this.choices[i].value, active: true, icon: this.choices[i].icon })
        } else {
          this.items.push({ title: this.choices[i].text, value: this.choices[i].value, active: false, icon: this.choices[i].icon })
        }
      }
    }
  },
  methods: {
    togle (val) {
      for (let i = 0; i < this.items.length; i++) {
        if (val !== i) {
          this.items[i].active = false
        } else {
          this.$emit('change', this.items[i])
        }
      }
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
