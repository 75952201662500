<template>
  <v-card>
      <v-card-text class="text-center mx-auto">
          <div class="pa-6"><v-avatar size="56">
              <v-img :src="require('@/assets/info.svg')"></v-img>
              </v-avatar> </div>
          <div class="primary--text text--darken-4 text-h5">{{title}}</div>
          <div class="secondary--text text-title-3">{{subtitle}}</div>

      </v-card-text>
      <v-card-actions>
   <v-row>
              <v-col cols="6" class="pa-2"><v-btn block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn block color="primary" large @click="$emit('onConfirm')"><span class="text-title-2">{{action}}</span></v-btn></v-col>
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
