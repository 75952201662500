<template>
  <v-card>
      <v-card-title class="text-h4 font-weight-bold">เพิ่มผู้ป่วยเข้ากลุ่มติดตาม<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="text-left">
         <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense class="ma-0 pa-0">
               <v-col cols="12">
                  <Select :title="'เลือกกลุ่ม'" :items="groupItems" item-text="groupName" item-value="id" v-model="value.groupId"/>
              </v-col>
          </v-row>
             </v-form>
              <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            กลุ่มที่อยู่ปัจจุบัน
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in value.group"
          :key="item.groupId"
        >
          <td>{{ item.groupName }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
      </v-card-text>
      <v-card-actions>
     <v-row class="pr-3 pl-3">
              <v-col cols="6" class="pa-2"><v-btn block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn block color="primary" large @click="onSubmit()"><span class="text-title-2">{{action}}</span></v-btn></v-col>
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import Select from '@/components/common/Select'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean
    },
    groupItems: {
      type: Array
    },
    value: {
      type: Object
    },
    site: {
      type: Object
    }
  },
  components: {
    Select
  },
  data () {
    return {
      valid: false
    }
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        // this.$emit('change', { fullname: this.fullname, address: this.address, mobile: this.mobile.replace(/^(\+)|\D/g, '$1'), gender: this.gender, birthdate: this.birthdate, cid: this.cid.replace(/^(\+)|\D/g, '$1') })
        this.$emit('onSubmit')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
