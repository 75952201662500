<template>
  <v-card>
      <v-card-title class="text-h4 font-weight-bold">พิมพ์คิวอาร์โค้ด</v-card-title>
      <v-card-text>
             <v-form ref="form" v-model="valid" lazy-validation>
                   <v-row dense class="ma-0 pa-0">
              <v-col cols="12">
                <Select :title="'ชื่อฟอร์ม'" :placeholder="'โปรดเลือก'" :items="formListItems" v-model="formId" item-text="name" :loading="dataLoading" return-object :rules="formRules" required />
              </v-col>
              <v-col cols="12">
                <MethodSelect :choices="choices" v-model="printerMode"/>
              </v-col>
              <v-col cols="12">
                แสดงคิวอาร์โค้ด
                <v-img class="mx-auto" :src="url" width="130"/>
              </v-col>
            <v-col cols="12" v-if="printerMode.value === 2">
                      <Select :title="'เลือกเครื่องพิมพ์'" :placeholder="'โปรดเลือก'" :items="printerList" v-model="printer" item-text="name" return-object :rules="printerRules" required />
               </v-col>
                   </v-row>
             </v-form>
      </v-card-text>
      <v-card-actions>
            <v-col cols="6" class="pa-2"><v-btn :disabled="posting" block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn :loading="posting" block color="primary" large @click="onSubmit()"><span class="text-title-2">สั่งพิมพ์</span></v-btn></v-col>
      </v-card-actions>
  </v-card>
</template>

<script>
import Select from '@/components/common/Select'
import MethodSelect from '@/components/common/MethodSelect'
import { getForm, getPatientToken } from '@/api/'
import { getPrinter } from '@/plugins/jsprintmanager'
import QRCode from 'qrcode'

export default {
  props: {
    patient: {
      type: Object
    }
  },
  components: {
    Select,
    MethodSelect
  },
  data () {
    return {
      valid: false,
      formListItems: [],
      dataLoading: false,
      formId: null,
      posting: false,
      formRules: [v => !!v || 'กรุณาเลือกแบบฟอร์ม'],
      printerRules: [v => !!v || 'กรุณาเลือกเครื่องพิมพ์'],
      printerList: [],
      printer: null,
      choices: [
        { text: 'ใช้เครื่องพิมพ์ตั้งต้น', value: 1, icon: 'mdi-printer-check' },
        { text: 'เลือกเครื่องพิมพ์', value: 2, icon: 'mdi-printer-search' }
      ],
      printerMode: { text: 'ใช้เครื่องพิมพ์ตั้งต้น', value: 1, icon: 'mdi-printer-check' },
      url: null
    }
  },
  mounted () {
    this.fetchData()
  },
  watch: {
    formId (val) {
      if (val) {
        this.renderQR()
      }
    }
  },
  methods: {
    async fetchData () {
      this.dataLoading = true
      if (this.$auth.role) {
        try {
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          const site = JSON.parse(sessionStorage.getItem('Site'))
          getForm({
            tenantId: tenant.tenantId,
            siteId: site.siteId,
            offset: 0,
            limit: 100,
            formTypeId: '',
            year: '',
            active: this.buildParam('active', [true])
          }, message => {
            if (message.data.code === 1) {
              if (message.data.result) {
                this.formListItems = message.data.result.items
                this.formId = message.data.result.items[0]
              } else {
                this.formListItems = []
              }
            }
            this.dataLoading = false
          }, error => {
            this.dataLoading = false
            console.log(error)
          })
          this.printerList = await getPrinter()
          this.printer = this.printerList[0]
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    },
    getToken () {
      this.posting = true
      setTimeout(function () {
        this.posting = false
        return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiaWF0IjoxNTE2MjM5MDIyLCJ0ZW5hbnQiOiJyYW1hIiwic2l0ZSI6ImRlbW8iLCJmb3JtSWQiOiJzc3Nzc3Nzc3NzIiwicGF0aWVudElkIjoiNTVkNDgzMzAtYzkyZS00ZTMxLTgxYTgtYTMxOTBjNDVlNjA4In0.JUXe7de2FgOzppHtM-dWqvNLD45uXIk7jlVnCOwWJsg'
      }, 2000)
    },
    buildParam (param, data) {
      let ret = ''
      for (let i = 0; i < data.length; i++) {
        if (i < 1) {
          ret += param + '=' + data[i]
        } else {
          ret += '&' + param + '=' + data[i]
        }
      }
      return ret
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.posting = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        const site = JSON.parse(sessionStorage.getItem('Site'))
        getPatientToken({ tenantId: tenant.tenantId, siteId: site.siteId, formId: this.formId.formId, cid: this.patient.cid }, message => {
          this.posting = false
          if (message.data.result.items.token) {
            this.$emit('onSubmit', message.data.result.items.token, (this.printerMode.value === 2) ? this.printer : null, this.formId, this.patient)
          }
        }, error => {
          console.log(error)
          this.posting = false
        })
      }
    },
    renderQR () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getPatientToken({ tenantId: tenant.tenantId, siteId: site.siteId, formId: this.formId.formId, cid: this.patient.cid }, message => {
        if (message.data.result.items.token) {
          const qrUrl = 'https://qr.vwell.care/idtoken/' + message.data.result.items.token
          QRCode.toDataURL(qrUrl)
            .then(url => {
              this.url = url
            })
            .catch(err => {
              console.error(err)
            })
          // this.$emit('onSubmit', message.data.result.items.token, (this.printerMode.value === 2) ? this.printer : null, this.formId, this.patient)
        }
      }, error => {
        console.log(error)
      })
    }
  }
}
</script>

<style>

</style>
