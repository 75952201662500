<template>
   <v-container fluid>
    <TitleBar :title="'ผู้ป่วย'" :subtitle="'รายชื่อผู้ป่วยที่ติดตามข้อมูล'">
      <template slot="action">
            <v-btn x-large color="primary" @click="createPatientDialog=true"><v-icon>mdi-plus</v-icon><span class="text-title-2">สร้างผู้ป่วย</span></v-btn>
          <!--   <v-btn x-large outlined @click="printBarCode">ทดสอบเครื่องพิมพ์</v-btn> -->
      </template>
    </TitleBar>
      <FilterBar>
      <template v-slot:left>
         <v-col cols="12" md="12" >
          <TextField v-model="search" :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ,อื่นๆ" @keypress.enter="fetchData"/>
         </v-col>
      </template>
      <template v-slot:right>
      </template>
    </FilterBar>
    <DataTable
    v-model="selectedItems"
    :server-items-length="totalItems"
    :options.sync="options"
    :items-per-page="50"
    :headers="headers"
    :loading="dataLoading"
    :search="search"
    item-key="cid"
    :footer-props="{
    'items-per-page-options': [50, 100, 150]
    }"
    show-select
    :items="items">
    <template v-slot:top>
    <v-row class="mb-2" >
      <v-spacer/>
      <v-col cols="auto">
        <v-btn color="primary" outlined class="" @click="bulkQROpen" :disabled="selectedItems.length === 0"> <v-icon >mdi-printer</v-icon> พิมพ์คิวอาร์โค้ด</v-btn>
      </v-col>
      </v-row>
       </template>
       <template v-slot:item.action="{ item }">
        <v-btn icon @click="editeItem(item)">
        <v-icon >mdi-pencil</v-icon></v-btn>
       </template>
        <template v-slot:item.group="{ item }">
         <v-btn icon @click="assignGroup(item)">
          <v-icon >mdi-account-group</v-icon>
        </v-btn>
       </template>
         <template v-slot:item.message="{ item }">
         <v-btn icon @click="viewMessage(item)">
          <v-icon >mdi-email</v-icon>
        </v-btn>
       </template>
          <template v-slot:item.print="{ item }">
         <v-btn icon @click="qrdialogOpen(item)">
          <v-icon >mdi-printer</v-icon>
        </v-btn>
       </template>
    </DataTable>
    <v-dialog v-model="createPatientDialog" width="450" persistent scrollable >
      <CreatePatientCard @onClose="createPatientDialog=false" @onSubmit="postPatient" :action="'สร้างผู้ป่วย'" v-model="patient" :key="createPatientDialog"/>
    </v-dialog>
     <v-dialog v-model="editPatientDialog" width="450" persistent scrollable >
      <EditPatientCard @onClose="editPatientDialog=false" @onSubmit="patchPatient" :action="'แก้ไขข้อมูล'" v-model="currentPatient" :key="editPatientDialog"/>
    </v-dialog>
     <v-dialog v-model="assignUserDialog" width="450" persistent scrollable >
      <AssignUserCard @onClose="assignUserDialog=false" @onSubmit="postGroupPatient" :action="'บันทึก'" v-model="currentPatient" :site="site" :key="assignUserDialog" :groupItems="groupItems"/>
    </v-dialog>
    <v-dialog v-model="messageDialog" max-width="600" persistent scrollable >
      <ChatAreaCard @onClose="messageDialog=false" :chatThread="threadId" :key="messageDialog" v-bind="currentPatient"/>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="450" persistent scrollable>
      <ConfirmCard @onClose="confirmDialog = false" @onConfirm="confirmDialog = false" :subtitle="'ไม่พบ Chat ID ในระบบ เนื่องจากข้อมูลผู้ป่วยถูกสร้างขึ้นในระบบ หรือผู้ป่วยถูกรายงานข้อมูลจากผู้ป่วยท่านอื่น'" :title="'แจ้งเตือน'" :action="'รับทราบ'"/>
    </v-dialog>
    <v-dialog v-model="qrdialog" width="450" persistent scrollable>
      <PrintQRCard @onClose="qrdialog=false" @onSubmit="printBarCode" :patient="currentPatient" :key="qrdialog"/>
    </v-dialog>
    <v-dialog v-if="selectedItems.length > 0" v-model="bulkQRDialog" width="450" persistent scrollable>
      <PrintBulkQRCard @onClose="bulkQRDialog=false" :patients="selectedItems" @onSubmit="bulkQRDialog=false"/>
    </v-dialog>
     <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
       <v-snackbar
        v-model="snackbar"
        color="natural darken-4"
      >
      <v-avatar size="35">
      <v-img width="35" :src="require('@/assets/circlesuccess.svg')"/>
      </v-avatar>
        {{errorMessage}}
        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>
              mdi-close
           </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import TextField from '@/components/common/TextField'
import FilterBar from '@/components/common/FilterBar'
import DataTable from '@/components/common/DataTable'
import CreatePatientCard from '@/components/patient/CreatePatientCard'
import PrintQRCard from '@/components/patient/PrintQRCard'
import PrintBulkQRCard from '@/components/patient/PrintBulkQRCard'
import EditPatientCard from '@/components/patient/EditPatientCard'
import AssignUserCard from '@/components/patient/AssignUserCard'
import ChatAreaCard from '@/components/chat/ChatAreaCard'
import ConfirmCard from '@/components/common/ConfirmCard'
import { getPatient, postPatient, patchPatient, getGroup, postGroupPatient, getChatThread, postChatThread } from '@/api/'
import { cloneDeep } from 'lodash'
import { initJSPrintManager, print } from '@/plugins/jsprintmanager'
export default {
  components: {
    TitleBar,
    TextField,
    FilterBar,
    DataTable,
    CreatePatientCard,
    EditPatientCard,
    AssignUserCard,
    ChatAreaCard,
    ConfirmCard,
    PrintQRCard,
    PrintBulkQRCard
  },
  data () {
    return {
      items: [],
      selectedItems: [],
      headers: [
        { text: '', value: 'data-table-select', class: 'primary lighten-5' },
        { text: 'เลขประจำตัวประชาชน', value: 'cid', class: 'primary lighten-5' },
        { text: 'ชื่อ-นามสกุล', value: 'fullname', class: 'primary lighten-5' },
        { text: 'เพศ', value: 'gender', class: 'primary lighten-5' },
        { text: 'ที่อยู่', value: 'address', class: 'primary lighten-5' },
        { text: 'เบอร์ติดต่อ', value: 'mobile', class: 'primary lighten-5' },
        { text: 'อีเมล', value: 'email', class: 'primary lighten-5' },
        { text: 'แก้ไข', value: 'action', class: 'primary lighten-5' },
        { text: 'กลุ่ม', value: 'group', class: 'primary lighten-5' },
        { text: 'ข้อความ', value: 'message', class: 'primary lighten-5' },
        { text: 'พิมพ์บัตร', value: 'print', class: 'primary lighten-5' }
      ],
      createPatientDialog: false,
      editPatientDialog: false,
      assignUserDialog: false,
      loading: false,
      dataLoading: false,
      search: null,
      yearItems: [],
      formTypeItems: [],
      formListItems: [],
      formId: null,
      formType: null,
      year: null,
      patient: {},
      currentPatient: {},
      group: null,
      groupItems: [],
      errorMessage: null,
      snackbar: false,
      site: null,
      threadId: null,
      messageDialog: false,
      confirmDialog: false,
      totalItems: 0,
      options: {},
      qrdialog: false,
      bulkQRDialog: false
    }
  },
  methods: {
    printBarCode (token, printer, from, patient) {
      const url = 'https://qr.vwell.care/idtoken/' + token
      print(url, printer, from, patient)
      this.qrdialog = false
    },
    buildParam (param, data) {
      let ret = ''
      for (let i = 0; i < data.length; i++) {
        if (i < 1) {
          ret += param + '=' + data[i]
        } else {
          ret += '&' + param + '=' + data[i]
        }
      }
      return ret
    },
    qrdialogOpen (item) {
      this.currentPatient = cloneDeep(item)
      this.qrdialog = true
    },
    bulkQROpen () {
      this.bulkQRDialog = true
    },
    editeItem (item) {
      this.currentPatient = cloneDeep(item)
      this.editPatientDialog = true
    },
    assignGroup (item) {
      this.currentPatient = item
      this.assignUserDialog = true
    },
    postGroupPatient () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      postGroupPatient({ tenantId: tenant.tenantId, siteId: site.siteId, cid: this.currentPatient.cid, groupId: this.currentPatient.groupId }, message => {
        if (message.data.code === 1) {
          this.assignUserDialog = false
          this.fetchData()
        } else {
          this.assignUserDialog = false
        }
      }, error => {
        console.log(error)
        if (error.response.data.code === 409) {
          this.assignUserDialog = false
          this.errorMessage = 'เพิ่มข้อมูลซ้ำ'
          this.snackbar = true
        } else {
          this.assignUserDialog = false
        }
      })
    },
    patchPatient () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      this.currentPatient.mobile = this.currentPatient.mobile.replace(/^(\+)|\D/g, '$1')
      this.currentPatient.cid = this.currentPatient.cid.replace(/^(\+)|\D/g, '$1')
      this.currentPatient.gender = (this.currentPatient.gender === 'ชาย') ? 'M' : (this.currentPatient.gender === 'หญิง') ? 'F' : null
      patchPatient({ tenantId: tenant.tenantId, siteId: site.siteId, id: this.currentPatient.id, etag: this.currentPatient._etag }, this.currentPatient, message => {
        if (message.data.code === 1) {
          this.editPatientDialog = false
          this.fetchData()
        }
      }, error => {
        console.log(error)
      })
    },
    postPatient () {
      this.createPatientDialog = false
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      postPatient({ ...this.patient, tenantId: tenant.tenantId, siteId: site.siteId }, message => {
        if (message.data.code === 1) {
          this.fetchData()
        }
      }, error => {
        console.log(error)
      })
    },
    async viewMessage (item) {
      try {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        const site = JSON.parse(sessionStorage.getItem('Site'))
        const thread = await getChatThread({ tenantId: tenant.tenantId, siteId: site.siteId, cid: item.cid, offset: 0, limit: 1 })
        if (thread.data.code === 1) {
          if (thread.data.result.items[0].chatThread) {
            this.threadId = thread.data.result.items[0].chatThread
            this.currentPatient = item
            this.messageDialog = true
          } else {
            const postThread = await postChatThread({ tenantId: tenant.tenantId, siteId: site.siteId, cid: item.cid })
            if (postThread.data.code === 1) {
              this.threadId = postThread.data.result
              this.currentPatient = item
              this.messageDialog = true
            }
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            console.log('ไม่พบ Chat ID ในระบบ เนื่องจากข้อมูลผู้ป่วยถูกสร้างขึ้นในระบบ หรือผู้ป่วยถูกรายงานข้อมูลจากผู้ป่วยท่านอื่น')
            this.confirmDialog = true
          }
        }
      } finally {
        this.loading = false
      }
    },
    async fetchData () {
      this.loading = true
      if (this.$auth.role) {
        // try {
        this.selectedItems = []
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        const site = JSON.parse(sessionStorage.getItem('Site'))
        this.site = site
        const resp = await getGroup({
          tenantId: tenant.tenantId,
          siteId: site.siteId
        })
        if (resp.data.code === 1) {
          this.groupItems = resp.data.result.items
          this.group = resp.data.result.items[0]
        } else {
          this.groupItems = []
        }
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        // if (itemsPerPage === -1) itemsPerPage = 5000
        getPatient({
          tenantId: tenant.tenantId,
          siteId: site.siteId,
          offset: (itemsPerPage * (page - 1)),
          limit: (itemsPerPage === -1) ? -1 : itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc,
          keyword: (this.search) ? this.search : ''
        }, message => {
          if (message.data.code === 1) {
            message.data.result.items.forEach(function (element) {
              element.editable = false
            })
            this.items = message.data.result.items
            this.items.sort(function (a, b) {
              var keyA = new Date(a.createDate).getTime()
              var keyB = new Date(b.createDate).getTime()
              // Compare the 2 dates
              if (keyA < keyB) return -1
              if (keyA > keyB) return 1
              return 0
            })
            this.totalItems = message.data.result.itemsLength
          } else {
            this.items = []
          }
          this.loading = false
        }, error => {
          console.log(error)
          this.loading = false
        })
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        // ()
        initJSPrintManager()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  beforeRouteLeave (to, from, next) {
    this.result = {}
    next()
  }
}
</script>

<style>

</style>
